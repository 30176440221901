.sidemenu {
    .mat-accordion .mat-expansion-panel:last-of-type, .mat-accordion .mat-expansion-panel:last-of-type {
        border-radius: 0px;
    }

    .mat-expansion-panel {
        box-shadow: unset;
        background-color: #ffffff;
        background: #ffffff;
        border: 1px solid #ffffff;
        .mat-expansion-panel-header {
            background: #ffffff;
            padding: 0px 20px;
            font-weight: normal;
        }
    }
}
