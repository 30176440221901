.text-secondary {
    color: $text-grey;
}

.link {
    cursor: pointer;
    color: $light-blue;
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid $light-blue;
      }
}

.text-small {
    font-size: 0.8em;
}

.text-big {
    font-size: 1.2em;
}

.text-tiny {
    font-size: 0.7em;
}

/* font colors*/
.text-green {
    color: $green;
}

.text-red {
    color: $red;
}

.text-blue {
    color: $blue;
}

.text-yellow {
    color: $yellow;
}

.text-light-blue {
    color: $light-blue;
}

.text-light-grey {
    color: $text-grey;
}

.text-grey {
    color: $grey;
}

.text-white {
    color: #ffffff;
}

.pointer {
    cursor: pointer;
}

.cut-off {
    // single line ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.helper-text, .text-help {
    font-size: 0.9em;
    color: $text-grey;
    font-style: italic;
}

.text-capital {
    text-transform: capitalize;
}

.text-bold {
    font-weight: bold;
}

.text-line-through {
    text-decoration: line-through;
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
