ul.breadcrumb {
    padding: 5px 8px;
    list-style: none;
    background-color: darken($light-background, 2%);
    color: $light-bg-darker-30;
    border-radius: $border-radius;
  }
  ul.breadcrumb li {
    display: inline;
    font-size: 14px;
  }
  ul.breadcrumb li+li:before {
    padding: 6px;
    color: $font;
    content: "/\00a0";
  }

  @media (max-width: 768px) { 
    // make it smaller for medium
    ul.breadcrumb li {
      font-size: 12px;
    }

    ul.breadcrumb li+li:before {
      padding: 2px;
    }
  }


  @media (max-width: 576px) { 
    // hide for small
    ul.breadcrumb {
      display: none;
    }
  }
