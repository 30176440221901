.mat-expansion-panel {
    box-shadow: unset !important;
    border: 1px solid $light-grey;
    background-color: white;

    .mat-expansion-panel-header {
        background: $light-grey;
        &:hover{
            background: $light-yellow !important;
        }
       
    }
}

