.project-status-bar {
    width: 100px;
    height: 17px;
    border: 1px solid $grey;
    border-radius: 20px;
    padding: 3px 4px;
    .actions {
        border-radius: 20px;
        height: 9px;
        overflow: hidden;
        .action {
            display: inline-block;
            height: 17px;
            border-right: 1px solid white;
        }
        .not_started {
            background-color: $grey;
        }
        .blocked {
            background-color: #000000;
        }
        .in_progress {
            background-color: $yellow;
        }
        .done {
            background-color: $green;
        }
        .cancelled {
            background-color: $red;
        }
    }
}