.super-heigh {
    color: $importanceSuperHigh
}

.heigh {
    color: $importanceHigh;
}

.normal {
    color: $importanceNormal;
}

.low {
    color: $importanceLow;
}

.veryLow {
    color: $importanceVeryLow;
}