.snackbar-success {
    border: 2px solid $green;
    background-color: #ffffff;
    color: $green;
}

.snackbar-warning {
    border: 2px solid $yellow;
    background-color: #ffffff;
    color: $yellow
}

.snackbar-danger {
    border: 2px solid $red;
    background-color: #ffffff;
    color: $red;
}