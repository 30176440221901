.w50 {
    width: 50px;
}

.w100 {
    width: 100px;
}

.w150 {
    width: 150px;
}

.w175 {
    width: 175px;
}

.w200 {
    width: 200px;
}

.w250 {
    width: 250px;
}

.w300 {
    width: 300px;
}

.w400 {
    width: 400px;
}

.w500 {
    width: 500px;
}

.w600 {
    width: 600px;
}