$blue: #112f69;
$light-blue: lighten($blue, 40);
$font: #353b48;

//red
$red: #c23616;
$light-red: lighten($red, 42);

//green
$green: #44bd32;
$light-green: lighten($green, 35);
$dark-green: lighten($green, 15);

//yellow
$yellow: #fbc531;
$mid-yellow: lighten($yellow, 20);
$light-yellow: lighten($yellow, 38);

//grey
$light-grey: #f5f6fa;
$grey: #dcdde1;
$dark-grey: #718093;
$text-grey: #a3a3a3;


$border-radius: 5px;

$link: $light-blue;

// project importance color:
$importanceSuperHigh: #ff7b00;
$importanceHigh: #ff9500;
$importanceNormal: #ffb700;
$importanceLow: #ffd000;
$importanceVeryLow: #ffea00;

$screen-small: 420px;
$screen-medium: 964px;

