.dlg {
    min-width: 350px;
    min-height: 250px;
    .dlg-header {
        padding: 10px 0px;
        display: flex;
        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
            font-style: italic;
        }
        .close {
            cursor: pointer;
            &:hover {
                color: $light-blue;
            }
        }
    }
    .dlg-body {
        min-height: 200px;
    }
    .dlg-footer {
        font-size: 0.8em;
        color: $grey;
    }
}
