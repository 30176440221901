/* You can add global styles to this file, and also import other style files */
// variables
@import 'app/styles/variables.scss';

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif;
    mat-drawer-container {
        height: 100%;
        mat-drawer.sidenav {
            min-width: 250px;
            button {
                text-align: left;
                display: block;
                width: 100%;
            }
           
        }
    }
}

// page content padding from the menu and the side of the browser
.page-container {
    height: 100%;
    padding: 10px 30px 30px 30px;
}
// all button should be pointer
button {
    cursor: pointer;
}

// reset material margins
p {
    margin: 0px;
}

a {
    color: $blue;
    text-decoration: none;
    &:hover {
        color: $light-blue;
    }
  }

.cursor {
    cursor: pointer;
}

#btn-menu-toggle {
    position: fixed;
    z-index: 100;
    margin-left: -7px;
}


// material override
@import 'app/styles/ich-material-theme.scss';
// helpers
@import 'app/styles/helpers/text.scss';
@import 'app/styles/helpers/spaces.scss';
@import 'app/styles/helpers/flexes.scss';
@import 'app/styles/helpers/bg-colors.scss';
@import 'app/styles/helpers/statuses.scss';
@import 'app/styles/helpers/importance.scss';
@import 'app/styles/helpers/misc.scss';
@import 'app/styles/helpers/widths.scss';

// partials
@import 'app/styles/partials/snackbar.scss';
@import 'app/styles/partials/table.scss';
@import 'app/styles/partials/forms.scss';
@import 'app/styles/partials/breadcrumb.scss';
@import 'app/styles/partials/sub-menu.scss';
@import 'app/styles/partials/dialog.scss';
@import 'app/styles/partials/grid.scss';
@import 'app/styles/partials/section.scss';
@import 'app/styles/partials/buttons.scss';
@import 'app/styles/partials/quickLog.scss';
@import 'app/styles/partials/bottomSheet.scss';
@import 'app/styles/partials/sidemenu.scss';
@import 'app/styles/partials/accordin.scss';


//projects
@import 'app/styles/partials/projects/project-importance.scss';
@import 'app/styles/partials/projects/project-status-bar.scss';
@import 'app/styles/partials/projects/project-actions-bar.scss';
@import 'app/styles/partials/projects/action-tree.scss';
