.action-tree {
  .action-tree-invisible {
    display: none;
  }
  
  ul,
  li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  
  /*
   * This padding sets alignment of the nested nodes.
   */
  .mat-nested-tree-node div[role=group] {
    padding-left: 20px;
  }
  
  /*
   * Padding for leaf nodes.
   * Leaf nodes need to have padding so as to align with other non-leaf nodes
   * under the same parent.
   */
  div[role=group] > .mat-tree-node {
    padding-left: 40px;
  }

  .action {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
} 