.project-importance-bar {
    .levels {
        display: flex;
        .level {
            background-color: #ffffff;
            width: 10px;
            height: 10px;
            border-radius: 2px;
            margin-right: 1px;
        }
        .level-1 {
            background-color: $importanceVeryLow;
        }
        .level-2 {
            background-color: $importanceLow;
        }
        .level-3 {
            background-color: $importanceNormal;
        }
        .level-4 {
            background-color: $importanceHigh;
        }
        .level-5 {
            background-color: $importanceSuperHigh;
        }
        .level-empty {
            background-color: $grey;
        }
    }
}