.section {
    background-color: #ffffff;
    padding: 10px 20px 10px 20px;
    margin-bottom: 50px;
    border-radius: $border-radius;
    .header {
        display: flex;
        padding: 10px 0px;;
        .title {
            flex: 1;
            span {
                font-size: 0.9rem;
                background-color: $yellow;
                border-radius: $border-radius;
                display: inline-block;
                padding: 2px 4px;
                color: #ffffff;
            }
           
        }
    }
    
}

// if the section in a grid it should be full width
.grid {
    .section {
        height: 100%;
        margin-bottom: 0px;
    }
}