/* helper flexbox classes */

.flex {
    display: flex;
    align-items: center;
    // main elemnt occupy all the nesessery space
    .full {
        flex: 1;
    }
}

.flex-inline {
    display: inline-flex;
}

.flex-align-item-start {
    align-items: start;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-space-between {
    // full widht 
    justify-content: space-between;
}

.flex-right {
   justify-content: flex-end;
}

.flex-center {
    justify-content: center;
    align-items: center;
}