
mat-table {
    border-radius: $border-radius;
}

//Mat-Table design
mat-header-row {
    border-radius: 0px;
    mat-header-cell {
        color: $blue !important;
        font-weight: bold;
    }
}

mat-row {
    &:hover {
        background-color: $light-yellow;
    }
}
// table with this calss rows are not pointers
.table-no-pointer {
    mat-row {
        cursor: default;
    }
}

// algin colum to right
mat-cell.align-right {
    display: flex;
    justify-content: flex-end;
}

//pagination buttons and total
.table-footer {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    .count {
        font-size: 0.8em;
        text-align: right;
    }
    .buttons-container {
        flex: 1;
        button {
            margin-left: 5px;
        }
    }
}

/* table - overwrite bootstrap */
.table {
    thead {
        th {
            border: 0px;
            background-color: #f9f9f9;
        }
    }
}

/*
material mobile optimised table for small window
*/

@media screen and (max-width: 960px) {
    .mat-table {
      border: 0;
      vertical-align: middle
    }
  
    .mat-table caption {
      font-size: 1em;
    }
    
    .mat-table .mat-header-cell {
      border: 10px solid;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    .mat-table .mat-row {
      border-bottom: 25px solid $light-grey;
      display: block;
    }
    /*
    .mat-table .mat-row:nth-child(even) {background: #CCC}
    .mat-table .mat-row:nth-child(odd) {background: #FFF}
    */
    .mat-table .mat-cell {
      //border-bottom: 1px solid #ddd;
      display: block;
      font-size: 1em;
      margin-bottom: 1%;
      margin-left: 2%;
    }
    .mat-table .mat-cell:before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: normal;
      
      font-size: .85em;
    }
    .mat-table .mat-cell:last-child {
      border-bottom: 0;
    }
      .mat-table .mat-cell:first-child {
      margin-top: 4%;
    }
  }
