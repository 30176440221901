.form-group {
    p {
        padding: 10px 0px;
    }
}
.from-group-inline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .form-control {
        padding: 0px 10px;
    }
    & > * {
        padding: 0px 5px;
    } 
}
