/* helper margin and padding */

.wAuto {
    width: auto;
}

/*
Paddings
*/
.padding-5, .p-5 {
    padding: 5px;
}

.padding-10, .p-10 {
    padding: 10px;
}

.padding-20, .p-20 {
    padding: 20px;
}

.padding-5-0, .p-5-0 {
    padding: 5px 0px;
}


.padding-10-0, .p-10-0 {
    padding: 10px 0px;
}

.padding-10 {
    padding: 10px;
}

.padding-20 {
    padding: 20px;
}

.padding-20-0 {
    padding: 20px 0px;
}

.p-l-10, .padding-l-10 {
    padding-left: 10px;
}
.p-r-10, .padding-r-10 {
    padding-right: 10px;
}

.p-l-20, .padding-l-20 {
    padding-left: 20px;
}

.p-l-30, .padding-l-30 {
    padding-left: 30px;
}

.p-r-20, .padding-r-20 {
    padding-right: 20px;
}

.p-b-20, .padding-b-20 {
    padding-bottom: 20px;
}
/*
Margins
*/
.margin-5, .m-5 {
    margin: 5px;
}

.margin-5-0, .m-5-0  {
    margin: 5px 0px;
}

.margin-10-0, .m-10-0  {
    margin: 10px 0px;
}

.margin-20-0, .m-20-0  {
    margin: 20px 0px;
}

.margin-l-10, .m-l-10 {
    margin-left: 10px;
}
.margin-l-20, .m-l-20 {
    margin-left: 20px;
}

.margin-r-10, .m-r-10 {
    margin-right: 10px;
}
.margin-r-20, .m-r-20 {
    margin-right: 20px;
}

.margin-t-20, .m-t-20 {
    margin-top: 20px;
}