.showQuickLog {
    cursor: pointer;
    mat-icon {
        color: $mid-yellow;
    }
    
}

.quick-log {
    padding: 10px;
    .header {
        mat-icon {
            font-size: 3em;
            color: $mid-yellow;
        } 
    }
}