.bg-blue {
    background-color: $blue;
}

.bg-green {
    background-color: $green;
}

.bg-light-green {
    background-color: $light-green;
}

.bg-red {
    background-color: $red;
}

.bg-light-grey {
    background-color: $light-grey;
}

.bg-grey {
    background-color: $grey;
}

.bg-error {
    background-color: $light-red;
}