.color-dot {
    width: 14px;
    height: 14px;
    border-radius:50%;
    display: inline-block;
    margin: 0px 5px;
}

.html-container, .text-container {
    // used for display html text mostly which is editable with the WYSIWYG editor
    padding: 3px 8px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.ref-number {
    font-size: 13px;
    padding: 5px 7px;
    letter-spacing: 1.5px;
    background-color: $light-blue;
    color: #ffffff;
    border-radius: $border-radius;
}

.file-upload-container {
    background-color: $light-yellow;
    border-radius: $border-radius;
    padding: 8px 10px;
    margin: 4px 0px;
    border: 2px dashed $mid-yellow;

    input[type=file]::file-selector-button {
        border: 0px solid #ffffff;
        padding: 3px 6px;
        border-radius: $border-radius;
        background-color: $blue;
        transition: 1s;
        color: white;
        font-size: 12px;
      }
      
      input[type=file]::file-selector-button:hover {
        background-color: $yellow;
      }
}

#headerTodayInput {
    width: 125px;
    text-align: right;
    font-size: 12px;
    padding: 0px 4px;
    border-radius: $border-radius;
    border: 0px solid #ffffff;

    &:focus{
        outline: none;
    }
}

.border-radius {
    border-radius: $border-radius;
}

.full {
    width: 100%;
}

.capitalize {
    text-transform: capitalize;
}

@media (max-width: $screen-medium) {
    .block-md {
        display: block !important;
    }
  }

@media (max-width: $screen-small) {
    .block-sm {
        display: block !important;
    }
}