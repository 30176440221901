/*default action status*/


// uggly hack - to apply the class which is the enum
.not_started {
    color: $grey;
}

.in_progress {
    color: $yellow;
}

.blocked {
    color: $red;
}

.done {
    color: $green;
}

.cancelled {
    color: #000000;
}
