.project-actions-bar {
    padding: 20px 10px;
    .actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        white-space: nowrap;
        .action {
            display: flex;
            align-items: center;
            padding: 2px 5px;
            border-radius: 5px;
            .number {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                background-color: $grey;
                width: 25px;
                height: 25px;
                border-radius: 25px;
                span {
                    color: #ffffff;
                    mat-icon {
                        margin-top: 5px;
                        margin-left: 4px;
                        font-size: 20px;
                    }
                }
            }
            .name {
                padding: 0px 5px;
                font-size: 0.90em;
            }
            &:hover {
                background-color: $light-grey;
            }
        }
    }
    .bar {
      height: 2px;
      width: 40px;
      background-color: $grey;  
    }

    //action
    .action.done{
        .number { background-color: $green; }    
    }
    .action.in_progress {
        .number { background-color: $yellow;  }    
    }
    .action.blocked {
        .number { background-color: $red;  }    
    }
    .action.cancelled {
        .number { background-color: black;  }    
    }
    //bar
    .bar.done { background-color: $green; }
    .bar.in_progress { background-color: $yellow;}
    .bar.blocked { background-color: $red;}
    .bar.cancelled { background-color: black;}

    
    @media screen and (max-width: $screen-medium) {
        .actions {
            display: block;
            .bar { display: none; }
            .action {padding: 10px 5px;}
        }
    }
}